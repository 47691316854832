// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Arial, sans-serif;
  background-color: white;
  margin: 0;
  padding: 0;
}
.div{background-color: #f8a8b5;
  padding: 50px 0; 
  text-align: center; 
  color: #fff;}

.form-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: rgba(255, 192, 203,0.3); /* Light pink background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h1, h2 {
  text-align: center;
}

label {
  display: block;
  margin: 15px 0 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button, input[type="image"] {
  width: 100%;
  padding: 12px;
  color: white;
  border: none;
  border-radius: 25px; /* Curvier edges */
  cursor: pointer;
  font-size: 16px;
  max-width: 200px; /* Limits button width */
  margin: 0 auto;
  display: block;
}

button:hover, input[type="image"]:hover {
  background-color: white; /* Darker pink on hover */
}

table {
  width: 100%;
}

td {
  padding: 10px;
}

td input[type="number"] { 
  width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,uBAAuB;EACvB,SAAS;EACT,UAAU;AACZ;AACA,KAAK,yBAAyB;EAC5B,eAAe;EACf,kBAAkB;EAClB,WAAW,CAAC;;AAEd;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,yCAAyC,EAAE,0BAA0B;EACrE,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;;;;EAIE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,mBAAmB,EAAE,kBAAkB;EACvC,eAAe;EACf,eAAe;EACf,gBAAgB,EAAE,wBAAwB;EAC1C,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,uBAAuB,EAAE,yBAAyB;AACpD;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;AACb","sourcesContent":["body {\n  font-family: Arial, sans-serif;\n  background-color: white;\n  margin: 0;\n  padding: 0;\n}\n.div{background-color: #f8a8b5;\n  padding: 50px 0; \n  text-align: center; \n  color: #fff;}\n\n.form-container {\n  max-width: 600px;\n  margin: 50px auto;\n  padding: 20px;\n  background-color: rgba(255, 192, 203,0.3); /* Light pink background */\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n}\n\nh1, h2 {\n  text-align: center;\n}\n\nlabel {\n  display: block;\n  margin: 15px 0 5px;\n}\n\ninput[type=\"text\"],\ninput[type=\"email\"],\ninput[type=\"tel\"],\ninput[type=\"number\"] {\n  width: 100%;\n  padding: 10px;\n  margin-bottom: 15px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\nbutton, input[type=\"image\"] {\n  width: 100%;\n  padding: 12px;\n  color: white;\n  border: none;\n  border-radius: 25px; /* Curvier edges */\n  cursor: pointer;\n  font-size: 16px;\n  max-width: 200px; /* Limits button width */\n  margin: 0 auto;\n  display: block;\n}\n\nbutton:hover, input[type=\"image\"]:hover {\n  background-color: white; /* Darker pink on hover */\n}\n\ntable {\n  width: 100%;\n}\n\ntd {\n  padding: 10px;\n}\n\ntd input[type=\"number\"] { \n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
