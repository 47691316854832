import React, { useState } from 'react';
import './App.css'; // Your custom styles for the form

const PersonalInfoForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    cellphone: '',
    customQuantity: 1,
    amount: 550.00
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const customQuantitiesPayFast = () => {
    setFormData((prevData) => ({
      ...prevData,
      amount: prevData.amount * prevData.customQuantity
    }));
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = customQuantitiesPayFast();

    if (isValid) {
      // Optionally submit the form
      e.target.submit();
    }
  };

  return (
    <div>
      

      {/* Pink banner section with form title */}
      <div style={{ backgroundColor: '#f8a8b5', padding: '30px 0',marginTop:'50px', color: '#fff' }}>
        <h1 style={{textAlign: 'left', marginLeft:'10px'}}>Registration Form </h1>
      </div>

      {/* Form Section */}
      <div className="form-container">
        <h1>Personal Information</h1>
        <form onSubmit={handleSubmit} action="https://payment.payfast.io/eng/process" method="post">
          <input type="hidden" name="cmd" value="_paynow" />
          <input type="hidden" name="receiver" value="11718503" />
          <input type="hidden" name="amount" value={formData.amount.toFixed(2)} />

          <label htmlFor="first-name">First Name *</label>
          <input 
            type="text" 
            id="first-name" 
            name="firstName" 
            value={formData.firstName} 
            onChange={handleInputChange} 
            required 
          />

          <label htmlFor="last-name">Last Name *</label>
          <input 
            type="text" 
            id="last-name" 
            name="lastName" 
            value={formData.lastName} 
            onChange={handleInputChange} 
            required 
          />

          <label htmlFor="email">Email Address *</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={formData.email} 
            onChange={handleInputChange} 
            required 
          />

          <label htmlFor="cellphone">Cellphone number *</label>
          <input 
            type="tel" 
            id="cellphone" 
            name="cellphone" 
            value={formData.cellphone} 
            onChange={handleInputChange} 
            required 
          />

          <label htmlFor="custom_quantity">Quantity:</label>
          <input 
            type="number" 
            id="custom_quantity" 
            name="customQuantity" 
            value={formData.customQuantity} 
            onChange={handleInputChange} 
            required 
          />

          <input type="hidden" name="item_name" value="Introduction to Coding" />
          <input type="hidden" name="item_description" value="Learn how to Code with GirlCode and be able to create your own websites." />

          <table>
            <tr>
              <td colSpan="2" align="center">
                <input type="image" src="https://my.payfast.io/images/buttons/PayNow/Primary-Large-PayNow.png" alt="Pay Now" title="Pay Now with Payfast" />
              </td>
            </tr>
          </table>
        </form>
      </div>
    </div>
  );
};

export default PersonalInfoForm;